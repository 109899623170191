import Splitting from "splitting";

import { $$ } from "@utils/dom";

const addLineOddEven = (el, options, ctx) => {
  const { lines } = ctx;
  lines.forEach((line, line_index) => {
    line.forEach(word => {
      const oddEven = line_index%2 ? 'even' : 'odd';
      word.setAttribute('data-odd-even', oddEven);
    });
  });

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'lineOddEven',
  key: 'lineOddEven',
  depends: ['lines'],
  split: addLineOddEven,
});


export default () => {
  [ ...$$('[data-splitting]') ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "chars";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
