import anime from "animejs";

import { EMITTER } from "@core/windmill.webpack-chunks.js"
import { findColorByPath } from "@transitions/TransitionColors";
import { $, $$ } from "@utils/dom";
import { moduleDelays, inViewport } from "./utils";

const SELECTOR = "[data-site-transition]";

class SiteTransition {
  constructor() {
    this.emitter = EMITTER;
    this.el = $(SELECTOR);
  }

  exiting({ next }) {
    // first, try to match url with our predefined routes
    const { background, color } = findColorByPath(next);

    // if background or color is defined, change background and color
    // otherwise, remove style attribute
    if (background || color) {
      if( background ) this.el.style.backgroundColor = background;
      if( color ) this.el.style.color = color;
    }
    else if (this.el.hasAttribute("style")) this.el.removeAttribute("style");
  }

  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [0, 1],
        duration: 250,
        easing: "linear",
        complete: () => resolve()
      });

      this.el.classList.remove("visibility-hidden");
    });
  }

  added(){
    moduleDelays(350, 450);

    // force a base --delay to each data-scroll-section in page, calc() on an undefined var breaks transition-delay
    [...$$(`main [data-scroll-section]`)].forEach((el, index) => {
      if (inViewport(el)) {
        // increment base delay to each data-scroll-section in view on init
        el.style.setProperty("--delay", `${300 * index}ms`);
      } else {
        el.style.setProperty("--delay", `0ms`);
      }
    });
  }

  enter() {
    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [1, 0],
        duration: 250,
        easing: "linear",
        complete: () => {
          // emit to other modules that site-transition is ready
          this.emitter.emit('SiteTransition.ready');
          // hide pane
          this.el.classList.add("visibility-hidden", "pointer-events-none");
          // resolve promise
          resolve();
        }
      });
    });
  }
}

export default SiteTransition;
